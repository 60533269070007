<template>
    <f7-page name="stays" ptr @ptr:refresh="refresh">
        <f7-navbar title="Pobyty i wpłaty">
            <f7-subnavbar :inner="false">
                <f7-searchbar
                        placeholder="Szukaj"
                        disable-button-text="Anuluj"
                        search-container=".search-list"
                        search-in=".item-title, .item-subtitle, .item-text"
                        :disable-button="!$theme.aurora"
                        @searchbar:clear="resetList"
                ></f7-searchbar>
            </f7-subnavbar>
        </f7-navbar>

        <f7-list media-list class="search-list" virtual-list :virtual-list-params="{ items: [], searchAll, renderExternal, height: itemHeight}">
            <ul>
                <f7-list-item
                        v-for="stay in vlData.items"
                        :key="stay.id"
                        :link="'/stay/'+stay.id+'/'"
                        :animate="!masterDetailMode"
                        :title="guestTitle(stay)|capitalize"
                        :style="`top: ${vlData.topPosition}px`"
                >
                    <div class="item-subtitle">{{stay.from|moment('D.MM.YYYY')}}-{{stay.to|moment('D.MM.YYYY')}}
                        <small>w {{stay.accommodation.name}}</small>
                    </div>
                    <div v-if="stay.requested" class="item-text">Należność: {{stay.requested|price}}</div>
                    <div class="item-text" v-if="stay.paid && !stay.cancelled">{{paidLabel(stay)}}</div>
                    <div class="item-text" v-if="stay.cancelled" style="color: red">{{cancelledLabel(stay)}}</div>
                    <div class="item-text unresolved" v-if="stay.leftToPay">Potwierdź pobranie</div>
                    <div class="item-text unresolved" v-if="stay.leftToRefund">Potwierdź zwrot</div>
                    <!--div class="item-text" v-if="stay.comments">Uwagi: <em>{{stay.comments}}</em></div-->
                </f7-list-item>
            </ul>
        </f7-list>


    </f7-page>
</template>
<script>
    export default {
        data: function () {
            return {
                virtualList: null,
                vlData: {
                    items: [],
                },
            };
        },
        computed: {
            itemHeight() {
                if(this.$f7.theme==='aurora') return 78;
                if(this.$f7.theme==='ios') return 106;
                if(this.$f7.theme==='md') return 113;
                return 100;
            },
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            stays: function() {
                return this.$store.getters.stays.items ? this.$store.getters.stays.items : [];
            },
            paidStays: function () {
                return this.stays.filter( ({paid}) => !!paid);
            },
            unpaidStays: function () {
                return this.stays.filter( ({paid}) => !!!paid);
            }
        },
        methods: {
            guestTitle(stay) {
                if(stay.bulk) {
                    return 'Wpłata łączna na ' + this.$options.filters.price(stay.charged);
                }
                let names = stay.guests.filter( ({name}) => !!name);
                let namesPart = names.map( ({ name }) => name ).join(', ');
                let countWithoutNames = stay.guests.filter( ({name}) => !!!name).length;

                let title = names.length>0 ? namesPart : '';

                if(countWithoutNames>0) {
                    if(names.length>0) title += ' i ';
                    if(countWithoutNames===1) title += 'jedna osoba w pokoju';
                    if(countWithoutNames===2) title += 'dwie osoby w pokoju';
                    if(countWithoutNames===3) title += 'trzy osoby w pokoju';
                    if(countWithoutNames===4) title += 'cztery osoby w pokoju';
                    if(countWithoutNames===5) title += 'pięć osób w pokoju';
                    if(countWithoutNames>5) title += countWithoutNames + ' osób w pokoju';
                }
                return title;
            },
            paidLabel: function (stay) {
              return stay.bulk ? 'Pobrana' : 'Pobrany';
            },
            cancelledLabel: function (stay) {
                return stay.bulk ? 'Odwołana' : 'Odwołany';
            },
            refresh(event, done) {
                this.$store.dispatch('stays').then(() => done());
            },
            searchAll(query, items) {
                this.$store.dispatch('filteredStays', {phrase: query});
                return [];
            },
            renderExternal(vl, vlData) {
                if(!!!this.virtualList) {
                    this.virtualList = vl;
                }

                if(vlData.toIndex>this.stays.length-10) {
                    this.$store.dispatch('nextStays')
                }

                this.vlData = vlData;
            },
            resetList() {
                this.$store.dispatch('stays');
            }
        },
        watch: {
            stays(newValue) {
                if(!!this.virtualList) {
                    this.virtualList.replaceAllItems(newValue);
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        },
    };
</script>

<style>
    .guests span:after {
        content: ', ';
    }
    .guests span:last-child:after {
         content: '';
     }
    .item-text.unresolved {
        color: rgb(223, 0, 0);
        font-weight: bold;
    }
</style>
