<template>
    <div class="guests-front-desk">
        <f7-block-header>Raportowane pobyty</f7-block-header>
        <f7-list inset inline-labels>
            <f7-list-input v-for="guests in guestsPerTier" :key="guests.tierId"
                    :input-id="elementId('guest-keypad', guests.tierId)"
                    input-style="text-align: right"
                    :label="hasManyTiers ? guests.tierName : 'Ilość osobodni'"
                    type="text"
                    validate
                    error-message="Podaj liczbę"
                    pattern="^\d*$"
                    placeholder="brak danych"
                    autocomplete="off"
                    :value="guests.count"
                    @change="guests.count = $event.target.value"
            >
            </f7-list-input>

            <f7-list-input
                    :input-id="elementId('amount-keypad')"
                    input-style="text-align: right"
                    label="Pobrana kwota"
                    type="text"
                    validate
                    required
                    error-message="Podaj kwotę"
                    pattern="^\d+,\d{2}( zł)?$"
                    placeholder="0,00 zł"
                    autocomplete="off"
                    :value="collectedAmount"
                    @input="collectedAmount = $event.target.value"
                    @change="collectedAmount = $event.target.value"
            >
            </f7-list-input>

            <f7-list-input
                label="Raport"
                type="file"
                accept=".pdf,.xls,.xlsx,.csv,.ods"
                multiple
                :inputId="'file_' + stayId"
                inputStyle="width: 100%"
            >
            </f7-list-input>
        </f7-list>
    </div>
</template>
<script>
    export default {
        props: {
            stayId: String,
            tiers: Array
        },
        data: function () {
            return {
                guestsPerTier: [],
                collectedAmount: null,
                id: null
            };
        },
        computed: {
            hasManyTiers: function () {
                return this.tiers.length>1;
            }
        },
        methods: {
            createAmountKeypad() {
                this.$f7.keypad.create({
                    inputEl: '#' + this.elementId('amount-keypad'),
                    dotButton: true,
                    dotCharacter: ',',
                    inputReadOnly: false, // for validation
                    toolbarCloseText: 'Gotowe',
                    formatValue: function (value) {
                        value = value.replace(',', '.');
                        if(value==='')
                        {
                            value = 0;
                        }
                        value = parseFloat(value);
                        value = value.toFixed(2).replace('.', ',');
                        return value + ' zł';
                    },
                    on: {
                        buttonClick(keypad) {
                            let parts = keypad.getValue().split(',');
                            if(parts[1] && parts[1].length>2) {
                                parts[1] = parts[1].substring(0, 2);
                            }
                            keypad.setValue(parts.join(','));
                        }
                    }
                });
            },
            createGuestsKeypad(tierId) {
                this.$f7.keypad.create({
                    inputEl: '#' + this.elementId('guest-keypad',  tierId),
                    dotButton: false,
                    toolbarCloseText: 'Gotowe'
                });
            },
            resetTiers(newTiers) {
                const initialValue = null;
                this.guestsPerTier = newTiers.map( (tier) => {
                    return {
                        tierId: tier.id,
                        tierName: tier.name,
                        count: initialValue
                    }
                });
            },
            elementId(...parts) {
                parts.push(this.id);
                return parts.join('__');
            }
        },
        mounted: function () {
            this.id = this._uid;
            this.resetTiers(this.tiers);

            this.$nextTick(function () {
                for (let guests of this.guestsPerTier) {
                    this.createGuestsKeypad(guests.tierId);
                }
                this.createAmountKeypad();
            });

        },
        watch: {
            tiers: function (newTiers) {
                this.resetTiers(newTiers);
            },
            guestsPerTier: {
                deep: true,
                handler: function () {
                    this.$emit('input', {form: {guestsPerTier: this.guestsPerTier}});
                }
            },
            collectedAmount: function () {
                this.$emit('input', {form: {collectedAmount: this.collectedAmount.replace(' zł', '')}});
            }
        },

    };
</script>
<style>
    .guests-front-desk .item-title.item-label {
        width: 50%;
    }
    .guests-front-desk .item-input-wrap {
        text-align: right;
    }
</style>
