<template>
    <form v-if="!sent" @submit="onSubmit" method="post" :action="formUrl">
        <input type="hidden" name="uuid" :value="form.uuid" />
        <input type="hidden" name="accommodationId" :value="form.accommodationId" />
        <input type="hidden" name="startsAt" :value="form.startsAt" />
        <input type="hidden" name="expiresAt" :value="form.expiresAt" />
        <input type="hidden" name="pages" :value="form.pages" />
        <f7-block-header>
            Kody rabatowe można generować tylko dla obiektów, które posiadają zbiorcze rejestrowanie pobytów.
            Wybierz okres ważności kodów (maksymalnie 14 dni) oraz ilość arkuszy. Jeden akursz mieści {{ codesPerPage }} kodów rabatowych.
        </f7-block-header>
        <f7-list inset no-hairlines-md>
            <f7-list-item class="country-select"
                          label="Obiekt noclegowy"
                          placeholder="Wybierz obiekt noclegowy"
                          smart-select :smart-select-params="{
                       openIn: 'popup',
                       searchbar: true,
                       searchbarPlaceholder: 'Wybierz obiekt noclegowy',
                       popupCloseLinkText: 'Zamknij',
                       searchbarDisableText: 'Anuluj',
                       closeOnSelect: true
                   }">
                <select v-model="form.accommodationId">
                    <option :value="null" selected disabled>Wybierz obiekt noclegowy</option>
                    <option v-for="a in accommodations" :value="a.id" :key="a.id">
                        {{nameForList(a)}}
                    </option>
                </select>
                <div class="item-title item-label">Obiekt noclegowy</div>
<!--                <div v-if="form.accommodationId===null" class="item-after">Wybierz obiekt noclegowy</div>-->
            </f7-list-item>
        </f7-list>
        <f7-list inset no-hairlines-md>
            <f7-list-input
                label="Okres ważności"
                type="datepicker"
                placeholder="Wybierz daty"
                :calendar-params="{
                    dateFormat: 'dd.mm.yyyy',
                    rangePicker: true,
                    closeOnSelect: true,
                    rangePickerMinDays: 2,
                    rangePickerMaxDays: 14,
                    minDate: new Date(Date.now() - (24*3600 * 1000))
                }"
                :value="dates"
                @calendar:change="dates = $event"
                :error-message="periodErrorMessage"
                :error-message-force="datesNotValidAndFormTouched"
            ></f7-list-input>
        </f7-list>
        <f7-list inset no-hairlines-md>
            <f7-list-item :title="'Ilość arkuszy (' + form.pages * codesPerPage + ' kuponów)'">
                <f7-stepper :min=1 :value=form.pages :step=1 :max=maxPages @input="form.pages = parseInt($event.target.value)"></f7-stepper>
            </f7-list-item>
        </f7-list>

        <f7-list inset>
            <f7-button raised large fill type="submit" @click="datePickerTouched = true" :disabled="disabled">{{submitLabel}}</f7-button>
        </f7-list>
    </form>


    <div v-else>
        <f7-block-header>
            Kody rabatowe są generowane, poczekaj na pobranie pliku PDF.
        </f7-block-header>

        <f7-list inset>
            <f7-button raised large fill type="submit" @click="reset">OK</f7-button>
        </f7-list>
    </div>
</template>

<script>
import uuid4 from 'uuid/v4'
import moment from 'moment';

export default {
    data() {
        return {
            dates: [],
            form: {
                uuid: uuid4(),
                accommodationId: null,
                startsAt: null,
                expiresAt: null,
                pages: 1
            },
            datePickerTouched: false,
            sent: false,
            disabled: false,
            codesPerPage: 10,
            maxPages: 10
        }
    },
    computed: {
        formUrl() {
            return this.$store.state.apiUri + 'coupons?token=' + this.$store.state.user.token;
        },
        datesNotValid() {
            return ! (!!this.dates && this.dates.length===2);
        },
        datesNotValidAndFormTouched() {
            return this.datesNotValid && this.datePickerTouched;
        },
        periodErrorMessage() {
            return 'Podaj okres ważności';
        },
        accommodations() {
            return this.$store.getters.accommodations.filter(accommodation => accommodation.coupons===true);
        },
        submitLabel() {
            return 'Generuj kody';
        }
    },
    watch: {
        dates(dates) {
            if(dates[0]) {
                this.form.startsAt = moment(dates[0]).format('YYYY-MM-D');
            }
            if(dates[1]) {
                this.form.expiresAt = moment(dates[1]).format('YYYY-MM-D');
            }
        }
    },
    methods: {
        reset() {
            this.dates = [];
            this.form = {
                uuid: uuid4(),
                accommodationId: null,
                startsAt: null,
                expiresAt: null,
                pages: 1
            };
            this.datePickerTouched = false;
            this.sent = false;
            this.disabled = false;
        },
        onSubmit(evt) {
            this.disabled = true;

            if(this.form.accommodationId===null) {
                evt.preventDefault();
                this.$f7.dialog.alert('Wybierz obiekt noclegowy');
                this.disabled = false;
                return;
            }

            if(this.datesNotValid) {
                evt.preventDefault();
                this.$f7.dialog.alert('Podaj datę pobytu');
                this.disabled = false;
                return;
            }

            let that = this;
            setTimeout(() => that.sent = true, 100);

            return true;
        },
        setDates(dates) {
            this.form.startsAt = dates[0] ? moment(dates[0]).format('YYYY-MM-D') : null;
            this.form.expiresAt = dates[1] ? moment(dates[1]).format('YYYY-MM-D'): null;
        },
        nameForList(accommodation) {
            let name = accommodation.name;
            if(this.sameNameAccommodations) {
                name = name + ' (' + accommodation.streetAndNumbers + ')';
            }
            return name;
        },
    }
}
</script>

<style>

/* fucking stupid f7 smart select does not support placeholder */
.smart-select-page li.disabled{
    display:none
}
</style>
