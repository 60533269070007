<template>
    <f7-page name="home" ptr @ptr:refresh="refresh">
        <f7-navbar>
            <f7-nav-title v-if="!masterDetailMode"><img :src="logo" height="20"></f7-nav-title>
            <f7-nav-title v-if="masterDetailMode">{{viewTitle}}</f7-nav-title>
        </f7-navbar>

        <register-form v-if="accommodation" :key="registerFormKey" @registered="registerFormKey += 1"></register-form>

        <f7-block v-else>
            Pobór opłaty jest niedostępny.
            W razie wątpliwości prosimy o kontakt z Urzędem.
        </f7-block>
    </f7-page>
</template>

<script>
    import logo from '../assets/logo-dark.svg'
    import registerForm from './components/register-form.vue';

    export default {
        data() {
            return {
                logo: logo,
                registerFormKey: 1
            }
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            accommodations: function () {
                return this.$store.getters.accommodations;
            },
            accommodation: function () {
                return this.$store.getters.accommodation;
            },
            viewTitle: function ()
            {
                if(!this.accommodation || this.accommodation.mode!=='front_desk_app') {
                    return 'Pobór opłaty';
                }

                return 'Rejestrowanie wpłaty';
            }
        },

        methods: {
            refresh(event, done) {
                this.$store.dispatch('accommodations').then(() => done());
            }
        },
        components: {
            'register-form': registerForm,
        }
    }
</script>

<style>
    .navbar {
        background: #0C3852;
        color: #fff
    }
</style>
