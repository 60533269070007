<template>
    <f7-page name="home" ptr @ptr:refresh="refresh">
        <f7-navbar>
            <f7-nav-title v-if="!masterDetailMode"><img :src="logo" height="20"></f7-nav-title>
            <f7-nav-title v-if="masterDetailMode">Kody rabatowe</f7-nav-title>
        </f7-navbar>

        <generate-codes v-if="accommodations" :accommodations="accommodations" :key="registerFormKey" @registered="registerFormKey += 1"></generate-codes>


        <f7-block v-if="!(accommodations && accommodations.length)">
            Generowanie kodów jest niedostępne dla Twoich obiektów.
        </f7-block>
    </f7-page>
</template>

<script>
import logo from '../assets/logo-dark.svg'
import GenerateCodes from './components/generate-codes.vue';

export default {
        data() {
            return {
                logo: logo,
                registerFormKey: 1
            }
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            accommodations: function () {
                return this.$store.getters.accommodations;
            },
        },

        methods: {
            refresh(event, done) {
                this.$store.dispatch('accommodations').then(() => done());
            }
        },
        components: {
            GenerateCodes
        }
    }
</script>

<style>
    .navbar {
        background: #0C3852;
        color: #fff
    }
</style>
