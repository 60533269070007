<template>
    <form @submit="submit">

        <div v-show="file.requires.accommodationId">
            <f7-block-title>Pobory dotyczą obiektu</f7-block-title>
            <f7-list inset no-hairlines-md>
                <li>
                    <accommodation-select :always-visible="true"></accommodation-select>
                </li>
            </f7-list>
        </div>


        <f7-block-title>Podsumowanie pliku</f7-block-title>
        <f7-list inline-labels no-hairlines-md inset>
            <f7-list-item title="Pobytów" :after="file.summary.stays|int"></f7-list-item>
            <f7-list-item title="Korekt" :after="file.summary.corrections|int"></f7-list-item>
            <f7-list-item title="Duplikatów" :after="file.summary.duplicates|int"></f7-list-item>
            <f7-list-item title="Błędów" :after="file.summary.errors|int"></f7-list-item>
            <!--f7-list-item title="Razem" :after="file.summary.total|int"></f7-list-item-->
        </f7-list>

        <f7-block-title>Podsumowanie wartości</f7-block-title>
        <f7-list inline-labels no-hairlines-md inset>
            <f7-list-item title="Pobytów" :after="file.amounts.stays|price"></f7-list-item>
            <f7-list-item title="Korekt" :after="file.amounts.corrections|price"></f7-list-item>
            <f7-list-item title="Duplikatów" :after="file.amounts.duplicates|price"></f7-list-item>
            <f7-list-item title="Błędów" :after="file.amounts.errors|price"></f7-list-item>
            <!--f7-list-item title="Razem" :after="file.amounts.total|price"></f7-list-item-->
        </f7-list>

        <div v-if="fileErrors.length">
            <f7-block-title>Błędne elementy</f7-block-title>
            <f7-list inline-labels no-hairlines-md inset>
                <f7-list-item v-for="error in fileErrors" :key="error.line"
                              :title="'Linia ' + error.line + ': ' + error.description"
                              :after="error.amount|price"></f7-list-item>
            </f7-list>
        </div>

        <div v-if="fileDuplicates.length">
            <f7-block-title>Duplikaty</f7-block-title>
            <f7-block>
                Poniższe elementy zostały już zaimportowane, zostaną pominięte w przypadku zatwierszenia importu.
            </f7-block>
            <f7-list inline-labels no-hairlines-md inset>
                <f7-list-item v-for="error in fileDuplicates" :key="error.line"
                              :title="'Linia ' + error.line + ': ' + error.description"
                              :after="error.amount|price"></f7-list-item>
            </f7-list>
        </div>


        <f7-list inset>
            <f7-button raised large fill type="submit">Zatwierdź import na {{fileValue|price}}</f7-button>
        </f7-list>
    </form>
</template>
<script>
import AccommodationSelect from "./accommodation-select"

export default {
    name: 'import-summary-form',
    components: {AccommodationSelect},
    computed: {
        accommodationId: function () {
            return this.$store.getters.accommodation.id;
        },
        file: function () {
            return this.$store.getters.import
        },
        fileErrors: function () {
            return this.file.items.filter(item => item.type==='error');
        },
        fileDuplicates: function () {
            return this.file.items.filter(item => item.type==='duplicate');
        },
        fileValue: function () {
            return this.file.amounts.stays + this.file.amounts.corrections;
        },
    },
    methods: {
        submit(event) {
            event.preventDefault();
            const payload = {file: this.file, accommodationId: this.accommodationId, f7: this.$f7};
            this.$store.dispatch('importStays', payload);
            return false;
        }
    }
}
</script>
