<template>
    <f7-page name="home" ptr @ptr:refresh="refresh">
        <f7-navbar>
            <f7-nav-title v-if="!masterDetailMode"><img :src="logo" height="20"></f7-nav-title>
            <f7-nav-title v-if="masterDetailMode">Import pobytów</f7-nav-title>
        </f7-navbar>

        <import-form v-if="accommodations && accommodations.length && showUploadForm" :key="registerFormKey" @registered="registerFormKey += 1"></import-form>

        <import-summary-form v-if="showSubmitForm" />


        <f7-block v-if="!(accommodations && accommodations.length)">
            Pobór opłaty jest niedostępny.
            W razie wątpliwości prosimy o kontakt z Urzędem.
        </f7-block>
    </f7-page>
</template>

<script>
import logo from '../assets/logo-dark.svg'
import ImportForm from './components/import-form.vue';
import ImportSummaryForm from './components/import-summary-form.vue';

export default {
        data() {
            return {
                logo: logo,
                registerFormKey: 1
            }
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            accommodations: function () {
                return this.$store.getters.accommodations;
            },
            accommodation: function () {
                return this.$store.getters.accommodation;
            },
            file: function () {
                return this.$store.getters.import
            },
            showUploadForm: function () {
                return this.file===null
            },
            showSubmitForm: function () {
                return this.file!==null
            }
        },

        methods: {
            refresh(event, done) {
                this.$store.dispatch('accommodations').then(() => done());
            }
        },
        components: {
            ImportSummaryForm,
            ImportForm,
        }
    }
</script>

<style>
    .navbar {
        background: #0C3852;
        color: #fff
    }
</style>
