<template>
    <form @submit="submit">
        <f7-block-header>Importuj z obiektu</f7-block-header>
        <f7-list inset no-hairlines-md>
            <f7-list-input
                label="Plik"
                type="file"
                :inputId="'file_' + id"
                inputStyle="width: 100%"
                required
            >
            </f7-list-input>
        </f7-list>

        <f7-list inset>
            <f7-button raised large fill type="submit">Wyślij plik</f7-button>
        </f7-list>


    </form>
</template>

<script>
import uuid4 from 'uuid/v4'

export default {
    data() {
        return {
            id: uuid4()
        }
    },
    computed: {

    },
    methods: {
        submit(event) {
            event.preventDefault();
            this.$store.dispatch('uploadStays', {id: this.id, f7: this.$f7});
            return false;
        }
    },
}
</script>

<style>
</style>
