<template>
    <f7-page name="report">
        <f7-navbar :back-link="masterDetailMode ? null : 'Powrót'">
            <f7-nav-title>{{report.name | capitalize}}</f7-nav-title>
        </f7-navbar>

        <f7-block-title>Pobrana opłata</f7-block-title>
        <f7-list inline-labels no-hairlines-md inset>
            <f7-list-item title="Kwota" :after="report.total|price"></f7-list-item>
            <f7-list-item title="Ilość pobytów" :after="report.paymentsCount"></f7-list-item>
        </f7-list>

        <f7-block-title v-if="report.refundsCount">Zwroty</f7-block-title>
        <f7-list v-if="report.refundsCount" inline-labels no-hairlines-md inset>
            <f7-list-item title="Ilość" :after="report.refundsCount"></f7-list-item>
        </f7-list>

        <f7-block-title v-if="report.closed">Płatność</f7-block-title>
        <f7-list v-if="report.closed" inline-labels no-hairlines-md inset>
            <f7-list-item title="Inkasent" :after="report.collector.name"></f7-list-item>
            <f7-list-item title="Za okres" :after="report.name"></f7-list-item>
            <f7-list-item title="Termin płatności" :after="report.due | moment('D.MM.YYYY')"></f7-list-item>
            <f7-list-item title="Do zapłaty" :after="report.total|price"></f7-list-item>
            <f7-list-item v-if="report.paid!==null" title="Zapłacono" :after="report.paid ? 'Tak' : 'Nie'"></f7-list-item>
            <f7-list-item v-if="report.overdue===true" title="Po terminie" :after="report.overdueDays + ' dni'"></f7-list-item>
        </f7-list>

        <f7-block-title v-if="report.closed">Konto bankowe</f7-block-title>
        <f7-list v-if="report.closed" inline-labels no-hairlines-md inset>
            <f7-list-item :title="report.bankAccount.name"></f7-list-item>
            <f7-list-item :title="report.bankAccount.number"></f7-list-item>
        </f7-list>


        <f7-list inline-labels no-hairlines-md inset>
            <f7-list-button v-if="report.closed" :external="true" :href="receiptUrl" title="Pobierz raport"></f7-list-button>
        </f7-list>



        <div v-if="report.fee && report.closed">
            <f7-block-title>Wynagrodzenie</f7-block-title>
            <f7-list inline-labels no-hairlines-md inset>
                <f7-list-item title="Razem" :after="report.fee|price"></f7-list-item>
            </f7-list>

            <f7-block-title v-if="showDocument">Rachunek za wynagrodzenie</f7-block-title>
            <f7-list v-if="showDocument" inline-labels no-hairlines-md inset>
                <f7-list-item title="Numer" :after="report.invoice.number"></f7-list-item>
                <f7-list-button :external="true" :href="downloadInvoiceUrl" title="Pobierz dokument"></f7-list-button>
                <f7-list-button v-if="showCorrect" color="orange" @click="correct=true" title="Popraw dokument"></f7-list-button>
            </f7-list>


            <form v-if="showGenerate" @submit="generateInvoiceConfirm">
                <f7-block-title>Wygeneruj {{ invoiceType }}</f7-block-title>
                <f7-list inline-labels no-hairlines-md inset>
                    <f7-list-input
                        label="Numer"
                        type="text"
                        validate
                        required
                        placeholder="Np. 1/OP/06/2021"
                        :value="number"
                        @input="number = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        label="Data wystawienia"
                        type="datepicker"
                        validate
                        required
                        :calendar-params="{dateFormat: 'dd.mm.yyyy', closeOnSelect: true}"
                        :value="[issueDate]"
                        @calendar:change="issueDate = formatDate($event[0])"
                    ></f7-list-input>
                    <f7-list-input
                        ref="bankAccountNumber"
                        label="Numer konta bankowego"
                        type="text"
                        validate
                        required
                        pattern="^ ?\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?$"
                        placeholder="00 0000 0000 0000 0000 0000 0000"
                        error-message="Podaj poprawny numer konta"
                        :value="bankAccount"
                        @input="bankAccount = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        label="Osoba upoważniona do wystawienia"
                        type="text"
                        validate
                        required
                        :value="authorizedPerson"
                        @input="authorizedPerson = $event.target.value"
                    ></f7-list-input>
                    <f7-list-item>
                        <f7-button type="submit">Wygeneruj {{ invoiceType }}</f7-button>
                    </f7-list-item>
                </f7-list>
            </form>

            <form v-if="showUpload" @submit="sendInvoiceConfirm">
                <f7-block-title v-if="showUpload">Dołącz dokument za wynagrodzenie</f7-block-title>
                <f7-list v-if="showUpload" inline-labels no-hairlines-md inset>
                    <f7-list-input
                        label="Numer"
                        type="text"
                        validate
                        required
                        placeholder="Np. 1/OP/06/2021"
                        :value="number"
                        @input="number = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        label="Plik"
                        type="file"
                        :inputId="'file_'+fileId"
                        :accept="report.actions.upload.format.join(',')"
                        inputStyle="width: 100%"
                        required
                    ></f7-list-input>
                    <f7-list-item>
                        <f7-button type="submit">Wyślij dokument</f7-button>
                    </f7-list-item>
                </f7-list>
            </form>
        </div>

        <div v-if="report.dailySummary">
            <f7-block-title>Raport dzienny</f7-block-title>
            <f7-list accordion-list inset>
                <f7-list-item v-for="(daySummary, no) in report.dailySummary" :key="no" accordion-item :title="daySummary.day | moment('D.MM.YYYY')">
                    <f7-accordion-content>
                        <f7-block>
                            <p v-for="(user, userNo) in daySummary.users" :key="userNo">{{user.name}}: <strong>{{user.amount|price}}</strong></p>
                        </f7-block>
                    </f7-accordion-content>
                </f7-list-item>
            </f7-list>
        </div>


    </f7-page>
</template>
<script>
    import uuid4 from 'uuid/v4'
    import moment from 'moment';

    export default {
        data: function () {
            return {
                number: null,
                fileId: uuid4(),
                file: null,
                correct: false,
                bankAccount: null,
                issueDate: null,
                authorizedPerson: null
            };
        },
        methods: {
            formatDate(dateString) {
                return moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
            },
            generateInvoiceConfirm(event) {
                event.preventDefault();

                this.$f7.dialog.confirm(
                    this.invoiceTypeCapitalized + ' można wygenerować tylko do raportu, który został opłacony. Czy kontynuować?',
                    'Uwaga!',
                    this.generateInvoice
                );
            },
            generateInvoice() {
                let vue = this;
                const payload = {
                    id: this.report.id,
                    number: this.number,
                    fileId: this.fileId,
                    bankAccount: this.bankAccount,
                    issueDate: this.issueDate,
                    authorizedPerson: this.authorizedPerson,
                    f7: this.$f7
                };
                this.$store.dispatch('generateInvoice', payload)
                    .catch( (error) => vue.$f7.dialog.alert(error.message ?? 'Wystąpił problem z generowaniem dokumentu, spróbuj ponownie.') );
            },
            sendInvoiceConfirm(event) {
                event.preventDefault();

                this.$f7.dialog.confirm(
                    'Rachunek można wysłać tylko do raportu, który został opłacony. Czy kontynuować?',
                    'Uwaga!',
                    this.sendInvoice
                );
            },
            sendInvoice() {
                let vue = this;
                this.$store.dispatch('sendInvoice', {id: this.report.id, number: this.number, fileId: this.fileId, f7: this.$f7})
                    .catch( (e) => vue.$f7.dialog.alert('Wystąpił problem z wysyłaniem dokumentu, spróbuj ponownie.') );
            }
        },
        computed: {
            masterDetailMode() {
                return this.$f7.width >= 800;
            },
            receiptUrl: function() {
                return this.$store.state.apiUri + 'reports/' + this.report.id + '.pdf?token=' + this.$store.state.user.token;
            },
            report() {
                var id = this.$f7route.params.id;
                return this.$store.getters.report(id);
            },
            invoiceType() {
                return this.report.actions.generate.type==='invoice' ? 'fakturę VAT' : 'rachunek'
            },
            invoiceTypeCapitalized() {
                return this.invoiceType.charAt(0).toUpperCase() + this.invoiceType.slice(1);
            },
            downloadInvoiceUrl: function() {
                return this.$store.state.apiUri + 'reports/' + this.report.id + '/invoice.pdf?token=' + this.$store.state.user.token;
            },
            showDocument: function () {
                return this.report.invoice;
            },
            showGenerate: function () {
                return this.report.actions.generate && (!this.showDocument || this.correct);
            },
            showUpload: function () {
                return this.report.actions.upload && (!this.showDocument || this.correct);
            },
            showCorrect: function () {
                return this.report.invoice && (this.report.actions.upload || this.report.actions.generate);
            }
        },
        mounted() {
            if(this.number===null && this.report.actions.generate) {
                this.number =  this.report.actions.generate.number;
            }

            if(this.report) {
                this.bankAccount = this.report.collector.bankAccount;
                this.issueDate = moment().format('YYYY-MM-DD'); // this.report.to;
                this.authorizedPerson = this.report.collector.authorizedPerson;
            }
        }
    };
</script>
