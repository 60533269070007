import Vue from 'vue'
import Vuex from 'vuex'
import jwt from 'jsonwebtoken'

Vue.use(Vuex);

import axios from 'axios';

const http = axios.create({
    baseURL: apiUri,
});

const debug = process.env.NODE_ENV !== 'production';

const store  = new Vuex.Store({
    state: {
        user: {
            logged: false,
            token: null,
            payload: null,
            pin: false
        },
        accommodation: null,
        accommodations: null,
        countries: [],
        stays: {items: [], notLoaded: true},
        tiers: [],
        reports: {items: [], notLoaded: true},
        import: null,
        apiUri: apiUri
    },
    getters: {
        tierName: state => id => {
            let tiers = [];
            // concat tiers form all accommodations
            for(const accommodation of state.accommodations) {
                tiers = [...tiers, ...accommodation.tiers];
            }
            const tier = tiers.find(tier => tier.id===id );
            return tier ? tier.name : null;
        },

        pinCreated: state => {
            return state.user.pin;
        },

        userLogged: state => {
            return state.user.logged;
        },

        countries: state => {
            if(state.countries.length===0) {
                store.dispatch('countries');
            }
            return state.countries;
        },
        accommodation: state => {
            return state.accommodation;
        },
        accommodations: state => {
            if(state.accommodations===null) {
                store.dispatch('accommodations');
            }
            return state.accommodations;
        },
        manyAccommodations: state => {
            return state.accommodations.length > 1;
        },
        stays: state => {
            if(state.stays.notLoaded) {
                store.dispatch('stays');
            }
            return state.stays;
        },
        stay: state => id => {
            return store.getters.stays.items.find(stay => stay.id === id);
        },
        import: state => {
            return state.import;
        },
        reports: state => {
            if(state.reports.notLoaded) {
                store.dispatch('reports');
            }
            return state.reports;
        },
        report: state => id => {
            return store.getters.reports.items.find(report => report.id === id)
        },
    },
    actions: {
        accommodations({commit}) {
            http.get('/dictionaries').then(response => {
                commit('accommodations', response.data);
                commit('accommodation', response.data[0]);
            });
        },
        countries({commit}) {
            http.get('/countries').then(response => {
                commit('countries', response.data);
            });
        },
        stays({commit}) {
            return http.get('/stays').then(response => {
                commit('stays', response.data);
            });
        },
        filteredStays({commit}, {phrase}) {
            const config = {
                params: {
                    phrase: phrase
                }
            };
            return http.get('/stays', config).then(response => {
                commit('stays', response.data);
            });
        },
        stay({commit}, {id}) {
            return http.get('/stays/' + id).then(response => {
                commit('stay', response.data);
            });
        },
        nextStays({state, commit}) {
            if(state.stays.links && state.stays.links.next) {
                return http.get(state.stays.links.next).then(response => {
                    commit('nextStays', response.data);
                });
            }
        },
        chargePayment({commit, dispatch}, {id, amount, paymentId, f7}) {
            return new Promise((resolve, reject) => {
                const data = JSON.stringify({amount: amount, paymentId: paymentId});
                f7.dialog.preloader('Rejestrowanie wpłaty...');

                http.patch('/stays/' + id + '/charge', data).then(response => {
                    dispatch('stay', {id: id});
                    f7.dialog.close();
                    resolve();
                }).catch( error => reject(error));
            });
        },
        refundPayment({commit, dispatch}, {id, amount, refundId, f7}) {
            return new Promise((resolve, reject) => {
                const data = JSON.stringify({amount: amount, refundId: refundId});
                f7.dialog.preloader('Rejestrowanie zwrotu...');
                http.patch('/stays/' + id + '/refund', data).then(response => {
                    dispatch('stay', {id: id});
                    f7.dialog.close();
                    resolve();
                }).catch( error => reject(error));
            });
        },
        updateLeavingDate({commit, dispatch}, {id, date, f7}) {
            const data = JSON.stringify({date: date});
            f7.dialog.preloader('Zmiana terminu wyjazdu...');
            return new Promise((resolve, reject) => {
                http.patch('/stays/' + id, data).then(response => {
                    dispatch('stay', {id: id}).then(() => {
                        f7.dialog.close();
                        resolve()
                    });
                }).catch(error => {
                    f7.dialog.close();
                    if(error.response && error.response.status===400) {
                        f7.dialog.alert('Data wyjazdu nie może być mniejsza niż data rozpoczęcia pobytu');
                        reject(error);
                        return;
                    }
                    f7.dialog.alert('Wystąpił nienany błąd systemu. Jeżeli problem będzie się ponawiał, skontaktuj się z administratorem.');
                    reject(error);
                });
            });
        },
        sendReceipt({commit, dispatch}, {id, email, f7}) {
            const data = JSON.stringify({email: email});
            f7.dialog.progress('Wysyłanie powiadomienia...');
            return new Promise((resolve, reject) => {
                http.post('/stays/' + id + '/receipt', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Wysłano', 100);
                    setTimeout(() => {
                        f7.dialog.close();
                        resolve();
                    }, 600);
                }).catch(error => {
                    var code = error.response.data.code;
                    if (code === 10301) error.message = 'Podany adres e-mail jest niepoprawny';
                    f7.dialog.close();
                    reject(error);
                });
            });
        },
        sendReceiptSms({commit, dispatch}, {id, phone, f7}) {
            const data = JSON.stringify({phone: phone});
            f7.dialog.progress('Wysyłanie powiadomienia...');
            return new Promise((resolve, reject) => {
                http.post('/stays/' + id + '/receipt/sms', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Wysłano', 100);
                    setTimeout(() => {
                        f7.dialog.close();
                        resolve();
                    }, 600);
                }).catch(error => {
                    var code = error.response.data.code;
                    if (code === 10317) error.message = 'Podany adres numer telefonu jest niepoprawny';
                    if (code === 10319) error.message = 'Numery zagraniczne zostały zablokowane w Twojej miejscowości. Skorzystaj z powiadomienia e-mail. W razie wątpliwości prosimy o kontakt z Urzędem.';
                    if (code === 10320) error.message = 'Wysyłka wiadomości do tego kraju została zablokowana w Twojej miejscowości. Skorzystaj z powiadomienia e-mail. W razie wątpliwości prosimy o kontakt z Urzędem.';
                    if (code === 10318) error.message = 'Wystąpił problem z wysyłką powiadomienia, spróbuj ponownie.';
                    f7.dialog.close();
                    reject(error);
                });
            });
        },
        cancelStay({commit, dispatch}, {id, reason, f7}) {
            const data = JSON.stringify({reason: reason});
            f7.dialog.progress('Odwoływanie...');
            return new Promise((resolve, reject) => {
                http.delete('/stays/' + id + '', { data: data }).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Odwołano', 100);
                    dispatch('stay', {id: id}).then(() => {
                        f7.dialog.close();
                        resolve()
                    });
                }).catch(error => {
                    f7.dialog.close();
                    reject(error);
                });
            });
        },
        reports({commit}) {
            return http.get('/reports').then(response => {
                commit('reports', response.data);
            });
        },
        generateInvoice({commit, dispatch}, {id, number, fileId, bankAccount, issueDate, authorizedPerson, f7}) {
            const data = new FormData();
            data.append('number', number);
            data.append('fileId', fileId);
            data.append('bankAccount', bankAccount);
            data.append('issueDate', issueDate);
            data.append('authorizedPerson', authorizedPerson);

            f7.dialog.progress('Generowanie...');
            return new Promise((resolve, reject) => {
                http.post('/reports/' + id + '/generate_invoice', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Dokument wygenerowany', 100);
                    dispatch('reports').then(() => {
                        f7.dialog.close();
                        resolve()
                    });
                }).catch(error => {
                    f7.dialog.close();
                    var code = error.response.data.code;
                    var details = error.response.data.details;
                    if (code === 10315) error.message = 'Nie można dołączać faktur do otwartych raportów';
                    if (code === 10316) error.message = 'Data wystawienia jest niepoprawna, dokument musi być wystawiony pomiędzy ' + details.possibleRange[0] + ' a ' + details.possibleRange[1];
                    reject(error);
                })
            });
        },
        sendInvoice({commit, dispatch}, {id, number, fileId, f7}) {
            const data = new FormData();
            data.append('number', number);
            data.append('fileId', fileId);

            let file = document.getElementById('file_' + fileId);
            data.append('file', file.files[0]);

            const options = {headers: {'Content-Type': 'multipart/form-data'}};

            f7.dialog.progress('Wysyłanie...');
            return new Promise((resolve, reject) => {
                http.post('/reports/' + id + '/invoice', data, options).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Dokument wysłany', 100);
                    dispatch('reports').then(() => {
                        f7.dialog.close();
                        resolve()
                    });
                }).catch(error => {
                    f7.dialog.close();
                    reject(error);
                });
            });
        },
        afterLogin({dispatch}) {
            dispatch('accommodations');
            dispatch('countries');
            dispatch('stays');
            dispatch('reports');
        },
        login({commit, dispatch}, {data, f7}) {
            http.post('/token', {
                login: data.login,
                password: data.password
            }).then(response => {
                try {
                    localStorage.setItem('token', response.data.token);
                    commit('login', response.data.token);
                    dispatch('afterLogin');
                } catch (e) {

                    f7.dialog.alert('Wystąpił nieznany błąd podczas logowania, skontaktuj się z administratorem systemu');

                }
            }).catch(e => {
                var message = e.response.data.error;
                var code = e.response.data.code;
                if (code === 10001) message = 'E-mail lub hasło jest niepoprawne';
                f7.dialog.alert(message);
            })
        },
        loginByPin({commit, dispatch}, {data, f7}) {
            return new Promise((resolve, reject) => {
                http.post('/token/pin', {
                    login: data.login,
                    pin: data.pin
                }).then(response => {
                    try {
                        localStorage.setItem('token', response.data.token);
                        commit('login', response.data.token);
                        dispatch('afterLogin');
                        resolve();
                    } catch (e) {
                        reject(e);
                        f7.dialog.alert('Wystąpił nieznany błąd podczas logowania, skontaktuj się z administratorem systemu');
                    }
                }).catch(e => {
                    var message = e.response.data.error;
                    var code = e.response.data.code;
                    if (code === 10004) message = 'Pin jest niepoprawny lub nieważny';
                    reject(message);
                })
            });

        },
        sendPin({commit, dispatch}, {data, f7}) {
            f7.dialog.preloader('Wysyłanie PINu...');
            http.post('/pin', {
                login: data.login
            }).then(response => {
                f7.dialog.close();
                commit('pin', 1);
            }).catch(e => {
                f7.dialog.close();
                var message = e.response.data.error;
                f7.dialog.alert(message);
            });
        },
        registerStay({commit, dispatch}, {data, f7}) {
            f7.dialog.preloader('Rejestrowanie pobytu...');

            const stayId = data.get('stayId');
            let file = document.getElementById('file_' + stayId);
            if(file)
            {
                [...file.files].forEach(file => data.append('files[]', file));
            }

            const options = {headers: {'Content-Type': 'multipart/form-data'}};

            return new Promise((resolve, reject) => {
                http.post('/stays', data, options).then(response => {
                    dispatch('stay', {id: stayId}).then(() => {
                        f7.dialog.close();
                        resolve();
                    });
                }).catch(error => {
                    f7.dialog.close();
                    if(error.response && error.response.status===400) {
                        this.$f7.dialog.alert('Popraw formularz');
                        return;
                    }
                    f7.dialog.alert('Wystąpił nienany błąd systemu. Jeżeli problem będzie się ponawiał, skontaktuj się z administratorem.');
                    reject(error);
                })
            });
        },
        uploadStays({commit, dispatch}, {id, accommodationId, f7}) {
            f7.dialog.progress('Wysyłanie...');
            f7.dialog.close();

            const data = new FormData();
            data.append('importId', id);
            data.append('accommodationId', accommodationId);

            let file = document.getElementById('file_' + id);
            data.append('file', file.files[0]);

            const options = {headers: {'Content-Type': 'multipart/form-data'}};

            f7.dialog.progress('Analizowanie...');
            return new Promise((resolve, reject) => {
                http.post('/stays/file', data, options).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Gotowe', 100);
                    f7.dialog.close();
                    commit('import', response.data);
                    resolve();
                }).catch(error => {
                    f7.dialog.close();
                    let message = 'Wystąpił problem z wysyłaniem dokumentu, spróbuj ponownie lub skontaktuj się z support@travelhost.pl';
                    let code = error.response.data.code;
                    if (code === 10402) message = 'Plik w tym formacie nie jest obsługiwany. W razie wątpliowści prosimy o kontakt z support@travelhost.pl';
                    //if (code === 10403) message = ;
                    f7.dialog.alert(message);
                    reject(error);
                });
            });
        },
        importStays({commit, dispatch}, {file, accommodationId, f7}) {
            f7.dialog.progress('Importowanie...');

            let data = JSON.stringify({
                accommodationId: accommodationId,
                import: file
            });

            return new Promise((resolve, reject) => {
                http.post('/stays/import', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.progress('Gotowe', 100);
                    f7.dialog.close();
                    f7.dialog.alert('Twój plik się importuje, może to potrwać nawet kilkanaście minut. Po zakończeniu powiadomimy Cie e-mailem.');
                    commit('import', null);
                    resolve();
                }).catch(error => {
                    f7.dialog.close();
                    let message = 'Wystąpił nieznany błąd, spróbuj ponownie lub skontaktuj się z support@travelhost.pl';
                    let code = error.response.data.code;
                    if (code === 10405) message = 'W pliku znajdują się już zaimportowane elementy. Sprawdź plik i spróbuj ponownie.';
                    f7.dialog.alert(message);
                    reject(error);
                });
            });
        },
        changePassword({commit, dispatch}, {data, f7}) {
            f7.dialog.preloader('Aktualizowanie hasła...');
            return new Promise((resolve, reject) => {
                http.patch('/user/password', data).then(response => {
                    f7.dialog.close();
                    f7.dialog.alert('Hasło zostało zmienione');
                    resolve();
                }).catch(e => {
                    f7.dialog.close();
                    var message = e.response.data.error;
                    var code = e.response.data.code;
                    if (code === 10003) message = 'Aktualne hasło jest niepoprawne';
                    if (code === 10004) message = 'Nowe hasło jest niepoprawne';
                    f7.dialog.alert(message);
                    reject(e);
                });
            });
        },
        logout({commit}) {
            commit('logout');
        }
    },
    mutations: {
        token (state, token) {
            state.user.token = token;
            state.user.payload = jwt.decode(token);
        },
        deleteToken (state) {
            state.user.token = null;
            state.user.payload = null;
        },
        accommodations (state, list) {
            state.accommodations = list.map(function (accommodation) {
                accommodation.streetAndNumbers =
                    accommodation.address.street +
                    ' ' +
                    accommodation.address.houseNo +
                    (accommodation.address.flatNo ? '/' + accommodation.address.flatNo : '')
                ;
                return accommodation;
            });
        },
        accommodation (state, accommodation) {
            state.accommodation = accommodation;
        },
        countries (state, list) {
            state.countries = list;
        },
        stays (state, list) {
            state.stays = list;
        },
        stay (state, stay) {
            let index = state.stays.items.findIndex(item => item.id===stay.id);
            if(!!state.stays.items[index]) {
                state.stays.items[index] = stay;
                state.stays.items = [...state.stays.items]; // rebuild for vuex
            } else {
                state.stays.items.unshift(stay);
            }
        },
        nextStays (state, list) {
            // push new items to the list
            list.items.forEach(function (item) {
                // prevent duplicates
                if(undefined===state.stays.items.find(stay => stay.id === item.id)) {
                    state.stays.items.push(item);
                }
            });
            state.stays.links = list.links;
        },
        reports (state, list) {
            state.reports = list;
        },
        login (state, token) {
            http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            state.user.logged = true;
            state.user.token = token;
            state.user.payload = jwt.decode(token);
        },
        pin (state, value) {
            state.user.pin = value;
        },
        logout (state) {
            state.user.logged = false;
            state.user.token = null;
            state.user.payload = null;
            state.user.pin = false;
            localStorage.removeItem('token')
        },
        import (state, value) {
            state.import = value;
        }
    },
    strict: debug
});


store.commit('login', localStorage.getItem('token'));

http.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        store.dispatch('logout');
        return;
    }
    return Promise.reject(error);
});


export default store;
