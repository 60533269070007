
import HomePage from '../pages/home.vue';
import ImportPage from '../pages/import.vue';
import StaysPage from '../pages/stays.vue';
import StayPage from '../pages/stay.vue';
import ReportPage from '../pages/report.vue';
import ReportsPage from '../pages/reports.vue';
import CodesPage from "../pages/codes.vue";

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/import',
    component: ImportPage,
  },
  {
    path: '/codes',
    component: CodesPage
  },
  {
    path: '/stays/',
    component: StaysPage,
    master: true,
    detailRoutes: [
      {
        path: '/stay/:id/',
        component: StayPage,
      },
    ]
  },
  {
    path: '/reports/',
    component: ReportsPage,
    master: true,
    detailRoutes: [
      {
        path: '/report/:id/',
        component: ReportPage,
      },
    ]
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
