<template>
    <div v-if="countries.length>0">
        <f7-block-header>Goście</f7-block-header>
        <f7-list inset no-hairlines-md v-for="(guest, guestNo) in guests" :key="guestNo">
            <f7-list-input label="Stawka" type="select" placeholder="Wybierz stawkę"
                           v-show="hasManyTiers"
                           @input="guest.tierId = $event.target.value"
                           :value="guest.tierId">
                <option v-for="tier in tiers" :value="tier.id">{{tier.name}}</option>
            </f7-list-input>
            <f7-list-input v-if="isRequired('fullName')"
                           label="Imię i nazwisko"
                           type="text"
                           validate
                           required
                           pattern=".+ .+"
                           placeholder="Imię i nazwisko gościa"
                           :value="guest.name"
                           @input="guest.name = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="isRequired('phone')"
                           label="Telefon"
                           type="text"
                           validate
                           required
                           pattern="^\+?[0-9]{9,15}"
                           placeholder="Telefon gościa"
                           :value="guest.phone"
                           @input="guest.phone = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="isRequired('pesel')"
                           label="PESEL"
                           type="text"
                           validate
                           required
                           pattern="[+0-9]{11}"
                           placeholder="PESEL"
                           :value="guest.pesel"
                           @input="guest.pesel = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="isRequired('email')"
                           label="E-mail"
                           type="email"
                           validate
                           required
                           placeholder="E-mail gościa"
                           :value="guest.email"
                           @input="guest.email = $event.target.value"
            ></f7-list-input>

            <f7-list-input v-if="isRequired('address') || isRequired('optionalAddress') || isRequired('postalCode') || isRequired('optionalPostalCode')"
                           label="Kod pocztowy"
                           type="text"
                           validate
                           :required="isRequired('address') || isRequired('postalCode')"
                           pattern=".{2,}"
                           placeholder="Kod pocztowy gościa"
                           :value="guest.address.postalCode"
                           @input="guest.address.postalCode = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="isRequired('address') || isRequired('optionalAddress')"
                           label="Miasto"
                           type="text"
                           validate
                           :required="isRequired('address')"
                           pattern=".{2,}"
                           placeholder="Miasto gościa"
                           :value="guest.address.city"
                           @input="guest.address.city = $event.target.value"
            ></f7-list-input>
            <f7-list-input v-if="isRequired('address') || isRequired('optionalAddress')"
                           label="Ulica"
                           type="text"
                           validate
                           :required="isRequired('address')"
                           pattern=".{2,}"
                           placeholder="Ulica gościa"
                           :value="guest.address.street"
                           @input="guest.address.street = $event.target.value"
            ></f7-list-input>
            <f7-list-item v-if="isRequired('address') || isRequired('optionalAddress') || isRequired('postalCode') || isRequired('optionalPostalCode')" class="country-select"
                          label="Kraj"
                          placeholder="Kraj gościa"
                          smart-select :smart-select-params="{
                       openIn: 'popup',
                       searchbar: true,
                       searchbarPlaceholder: 'Wybierz kraj',
                       popupCloseLinkText: 'Zamknij',
                       searchbarDisableText: 'Anuluj',
                       closeOnSelect: true,
                   }">
                <select v-model="guest.address.countryCode">
                    <optgroup v-for="group in countries" :label="group.name">
                        <option v-for="country in group.countries" :value="country.code">{{country.name}}</option>
                    </optgroup>
                </select>
                <div class="item-title item-label">Kraj</div>
            </f7-list-item>
            <f7-list-button title="Usuń gościa" color="red" @click="removeGuest(guestNo)"></f7-list-button>
        </f7-list>

        <f7-list inset>
            <f7-list-button title="Kolejny gość" @click="addGuest(tiers[0].id)"></f7-list-button>
        </f7-list>
    </div>
</template>
<script>
    export default {
        props: {
            tiers: Array,
            required: Array
        },
        data: function () {
            return {
                guests: [],
            };
        },
        computed: {
            hasManyTiers: function () {
                return this.tiers.length>1;
            },
            countries: function () {
                return this.$store.getters.countries;
            }
        },
        methods: {
            isRequired(field) {
                return this.required.indexOf(field) >= 0;
            },
            addGuest: function (tierId) {
                this.guests.push({
                    tierId: tierId ?? this.tiers[0].id,
                    pesel: '',
                    name: '',
                    phone: '',
                    email: '',
                    address: {
                        countryCode: 'PL',
                        postalCode: '',
                        city: '',
                        street: ''
                    }
                })
            },
            removeGuest: function (no) {
                this.guests.splice(no - 1, 1);
            }
        },
        watch: {
            guests: {
                deep: true,
                handler: function () {
                    this.$emit('input', {guests: this.guests});
                }
            },
        },
        mounted() {
            this.addGuest();
        }

    };
</script>
